<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span>Crear Usuario</span>
            </v-card-title>
            <v-card-text>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >
                    <v-text-field
                        v-model="user.name"
                        :counter="200"
                        label="Name"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="user.email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                    ></v-text-field>
                    <b-form-group
                    description="Marca mas de 1 permiso manteniendo la tecla ctrl."
                    label="Permisos"
                    >
                        <b-form-select style="height: 300px;" v-model="user.permissions" multiple class="mb-3">
                            <optgroup v-for="(attribute,i) in Object.keys(permissionsList)" :key="i" :label="attribute">
                                <option v-for="(permiso,index) in permissionsList[attribute]" :key="index" :value="permiso.id">{{permiso.name}}</option>
                            </optgroup>
                        </b-form-select>
                    </b-form-group>
                    <v-select
                    :items="storesList"
                    v-model="user.store_id"
                    label="Store"
                    ></v-select>
                    <v-text-field
                        v-model="user.password"
                        :rules="passwordRules"
                        label="Contraseña"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="user.password_confirmation"
                        :rules="confirmationRules"
                        label="Confirmar contraseña"
                        required
                    ></v-text-field>
                    <v-switch v-model="user.active" label="Activo" >

                    </v-switch>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            valid: true,
            showDialog: false,
            permisosSelected: [],
            user_id: undefined,
            user: {
                name: '',
                email: '',
                permissions: [],
                password: '',
                password_confirmation: '',
                store_id: undefined,
                active: true,
            },
            select: [],
            emailRules: [
                value => !!value || 'Requerido.',
                value => (value || '').length <= 200 || 'Máximo 200 caracteres',
                value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Email inválido.'
                },
            ],
            passwordRules: [
                value => !!value || 'Requerido.',
                value => (value || '').length <= 200 || 'Máximo 200 caracteres',
            ],
            confirmationRules: [
                value => !!value || 'Requerido.',
                value => (value || '').length <= 200 || 'Máximo 200 caracteres',
                value => (value === this.user.password) || 'No coinciden.'
            ],
            permissionsList: {},
            storesList: [],
        }
    },
    created(){
        var vm = this;
        this.axios({
            url: 'admin/permissions',
            method: 'GET',
        }).then( response => {
            vm.permissionsList = response.data;
        }).catch( error => {
            console.log(error);
        });
        
        this.$parent.$on('openDialogCrearUsuario', () => {
            this.showDialog = true;     
        });
    },
    methods: {
        sendForm(){
            console.log('se deberia enviar el formulario');
            var vm = this;
            this.axios({
                url: 'admin/users',
                method: 'POST',
                data: this.user
            }).then( () => {
                vm.showDialog = false;
                vm.$emit('success');
            }).catch( error => {
                console.log(error);
            });
        },
        
    },
    mounted()
    {
        var vm = this;
        this.axios({
            url: 'orders/stores/data_select_input',
            method: 'GET',
        }).then( response => {
            vm.storesList = response.data;
        }).catch( error => {
            console.log(error);
        })
    }
}
</script>