<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="160px">
        <v-card>
            <v-card-title>
                <span>¿Está seguro?</span>
            </v-card-title>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    No
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="sendForm"
                    >
                    Si
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        var vm = this;
        return {
            user_id: undefined,
            showDialog: false,
        }
    },
    created(){
        this.$parent.$on('openDialogEliminarUsuario', (user_id) => {
            this.user_id = user_id;
            this.showDialog = true;
        });
    },
    methods: {
        sendForm(){
            console.log('se deberia enviar el formulario');
            var vm = this;
            this.axios({
                url: 'admin/user/delete',
                method: 'POST',
                data: {user_id: this.user_id}
            }).then( () => {
                vm.showDialog = false;
                vm.$emit('success');
            }).catch( error => {
                console.log(error);
            });
        },
        
    },
}
</script>