<template>
<div>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Usuarios en sistema
                        <v-spacer></v-spacer>
                        <v-btn class="" @click="fetchData"  text icon color="blue">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        <div class="mx-0">
                            <v-btn @click="abrirDialogEditarUsuario" color="blue" dark v-show="selected.length == 1">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </div>
                        <div class="mx-4">
                            <v-btn @click="$emit('openDialogCrearUsuario')" icon color="blue"  dark >
                                <v-icon> icon-new_action-2</v-icon>
                            </v-btn>
                        </div>
                        <div class="mx-0">
                            <v-btn @click="abrirDialogEliminarUsuario" icon color="red" dark v-show="selected.length == 1">
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </div>
                    </v-card-title>
                    <v-card-text >
                        <div class="d-flex justify-space-between mb-4">
                        <v-text-field
                            v-model="search"
                            clearable
                            label="Buscar"
                            hide-details
                            dense
                            solo
                            class="mr-3"
                        ></v-text-field>
                        <v-btn class="btn-starkoms-primary" style="min-width: 138px;" dark>Buscar</v-btn>
                        </div>
                    </v-card-text>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="tableData"
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            :search="search"
                            :single-select="true"
                            show-select
                            v-model="selected"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                            class="elevation-1 datatable"
                        >
                            <template v-slot:item.name="{item}">
                                <td class="text-start" >
                                    <span v-if="!item.active" class="text--disabled">{{item.name}}</span>
                                    <span v-if="item.active">{{item.name}}</span>
                                    {{item.active ? '' : '(inactivo)'}}
                                </td>
                            </template>
                            <template v-slot:item.email="{item}">
                                <td class="text-start">
                                    <span v-if="!item.active" class="text--disabled">{{item.email}}</span>
                                    <span v-if="item.active">{{item.email}}</span>
                                </td>
                            </template>
                            <!--
                            <template v-slot:item.acciones="{ item }">
                                <td>
                                    <v-icon
                                        small
                                        class="mr-2"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                        small
                                    >
                                        mdi-delete
                                    </v-icon>
                                </td>
                            </template>
                            -->
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination v-model="page" :length="pageCount" :total-visible="10"></v-pagination>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-app>
    <dialog-editar-usuario @success="fetchData"></dialog-editar-usuario>
    <dialog-crear-usuario @success="fetchData"></dialog-crear-usuario>
    <dialog-eliminar-usuario @success="fetchData"></dialog-eliminar-usuario>
</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogEditarUsuario from "@/views/oms/Admin/DialogEditarUsuario.vue";
import DialogCrearUsuario from '@/views/oms/Admin/DialogCrearUsuario.vue';
import DialogEliminarUsuario from '@/views/oms/Admin/DialogEliminarUsuario.vue';
export default {
    components: {
        DialogEditarUsuario,
        DialogCrearUsuario,
        DialogEliminarUsuario
    },
    data () {
            return {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                search: '',
                selected: [],
                headers: [
                    {
                    text: 'ID',
                    align: 'left',
                    sortable: true,
                    value: 'id',
                    },
                    { text: 'Nombre', value: 'name' },
                    { text: 'Email', value: 'email' },
                    { text: 'Fecha creación', value: 'creacion' },
                    { text: 'Fecha actualización', value: 'actualizacion' },
                    { text: 'Acciones', value: 'acciones' },
                ],
                tableData: [],
            }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard Usuarios", route: "usuarios" },
        ]);
        this.fetchData();
    },
    methods: {
        abrirDialogEditarUsuario(){
            let id = this.selected[0].id;
            this.$emit('openDialogEditarUsuario', id);
        },
        abrirDialogEliminarUsuario(){
            let id = this.selected[0].id;
            this.$emit('openDialogEliminarUsuario', id);
        },
        fetchData()
        {
            var vm = this;
            this.axios({
                url: 'admin/users',
                method: 'GET',

            }).then( response => {
                /**Pagination */
                vm.pageCount = Math.ceil(response.data.length / 10);
                vm.tableData = response.data;
            }).catch( error => {
                console.log(error);
            })
        }
    }
}
</script>

<style>

</style>